<template>
  <b-modal
    id="add-new-vaccine-sidebar"
    centered
    :visible="isAddNewVaccineSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    @ok="$refs.submitButtonRef.click()"
    @hidden="formValidation(resetblankvaccine).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("vaccineAdd") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankvaccine
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- Asiadi -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Asiadi"
          >
            <b-form-group label="vaccineName" label-for="Asiadi">
              <b-form-input
                id="aAsiadi"
                v-model="blankVaccineData.Asiadi"
                autofocus
                :state="
                  formValidation(resetblankvaccine).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Suteetkiedengun -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Suteetkiedengun"
          >
            <b-form-group label="Suteetkiedengun" label-for="Suteetkiedengun">
              <b-form-input
                id="aSuteetkiedengun"
                v-model="blankVaccineData.Suteetkiedengun"
                autofocus
                :state="
                  formValidation(resetblankvaccine).getValidationState(
                    validationContext
                  )
                "
                type="number"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- stokmiktari -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="stokmiktari"
          >
            <b-form-group label="stokmiktari" label-for="stokmiktari">
              <b-form-input
                id="astokmiktari"
                v-model="blankVaccineData.stokmiktari"
                autofocus
                :state="
                  formValidation(resetblankvaccine).getValidationState(
                    validationContext
                  )
                "
                trim
                type="number"
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewVaccineSidebarActive",
    event: "update:is-add-new-vaccine-sidebar-active",
  },
  props: {
    isAddNewVaccineSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankVaccineData: {
        id: 0,
        Asiadi: "",
        plantid: this.$store.state.app.selectedPlantId,
        Suteetkiedengun: 0,
        stokmiktari: 0,
      },
      formValidation: formValidation,
    };
  },
  async mounted() {},
  methods: {
    async changed(val) {
      this.$emit("update:is-add-new-vaccine-sidebar-active", val);
      this.resetblankvaccine();
    },
    onSubmit() {
      store
        .dispatch("definitionsModule/addVaccine", this.blankVaccineData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-add-new-vaccine-sidebar-active", false);
        });
    },
    async resetblankvaccine() {
      this.blankVaccineData = {
        id: 0,
        Asiadi: "",
        plantid: this.$store.state.app.selectedPlantId,
        Suteetkiedengun: 0,
        stokmiktari: 0,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-vaccine-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
