<template>
  <div><vaccine-list /></div>
</template>

<script>
import VaccineList from "./components/VaccineList/VaccineList.vue";
export default {
  components: { VaccineList },
};
</script>

<style></style>
